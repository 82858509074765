// Swiper
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// Swiper
window.Swiper = Swiper;

// Fancybox
// import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

// window.Fancybox = Fancybox