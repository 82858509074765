document.addEventListener('DOMContentLoaded', () => {
    const dropdownToggles = document.querySelectorAll('.dropdown-toggle');

    dropdownToggles.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            el.parentElement.classList.toggle('dropdown-open');
        })
    })
});