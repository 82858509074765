import CustomCursor from "custom-cursor.js";

// Following options represent the defaults
const options = {
    hideTrueCursor: true,
    focusElements: [
        {
            elements: '.benefitsCarousel, .projectsCarousel, .commentsCarousel, .featuredCarousel, .projectsDetailCarousel',
            focusClass: 'cursor--drag',
        },
        {
            elements: 'input, textarea',
            focusClass: 'cursor--form',
        },
        {
            elements: 'a, button, .btn, .toggle, .modal-close, .body-text-link, .accordion-head',
            focusClass: 'cursor--special-focused',
        },
    ],
}

const element = '.cursor'
new CustomCursor(element, options).initialize()