const selectIcon = 'icon-chevron-down';


const uid = function() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

const kebabCase = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.form-select');

    selects.forEach(el => {
        init(el);
    })
});


const init = (el) => {
    el.style.display = 'none';

    const parentId = uid();

    el.dataset.id = parentId;

    const mockup = createSelectMockup(el, parentId);

    if (el.nextSibling) {
        el.parentElement.insertBefore(mockup, el.nextSibling);
    } else {
        el.appendChild(mockup)
    }
}

const createSelectMockup = (parent, parentId) => {
    const container = createContainer();

    const button = createButton(parentId);

    const dropdown = createDropdown(parentId);

    fillDropdownByParentId(dropdown, parent, parentId, button);

    container.appendChild(button);

    container.appendChild(dropdown);

    return container;
}

const createContainer = () => {
    let container = document.createElement('div');

    container.classList.add('form-select__container');

    return container;
}

const createButton = (parentId) => {
    let button = document.createElement('div');

    button.classList.add('form-select__button');

    button.addEventListener('click', () => {
        const opened = button.parentElement.classList.contains('open');

        document.querySelectorAll('.form-select__container.open')
            .forEach(el => el.classList.remove('open'))

        const parent = document.querySelector(`.form-select[data-id="${parentId}"]`);

        const dropdown = document.querySelector(`.form-select__dropdown[data-parent-id="${parentId}"]`);

        if (opened) {
            button.parentElement.classList.remove('open');
        } else {
            fillDropdownByParentId(dropdown, parent, parentId);

            dropdown.scrollTo({
                top: 0,
            });

            button.parentElement.classList.add('open');
        }
    });

    return button;
}

const createDropdown = (parentId) => {
    let dropdown = document.createElement('div');

    dropdown.classList.add('form-select__dropdown');

    dropdown.dataset.parentId = parentId;

    return dropdown;
}

const selectByValue = (parentId, value) => {
    const parent = document.querySelector(`[data-id="${parentId}"]`);

    if (!parent) return;

    parent.dispatchEvent(
        new Event('change')
    );

    parent.value = value;

    parent.nextElementSibling.classList.remove('open');

    const dropdown = parent.nextElementSibling.querySelector('.form-select__dropdown');

    const button = parent.nextElementSibling.querySelector('.form-select__button');

    if (!dropdown) return;

    fillDropdownByParentId(dropdown, parent, parentId, button);
}

const createSearchInput = (parentId) => {
    const parent = document.querySelector(`.form-select[data-id="${parentId}"]`);

    const input = document.createElement('input');

    Object.entries(parent.dataset).forEach(([key, value]) => {
        if (!key.startsWith('search') || key === 'searchable') {
            return;
        }

        key = key.replace('search', '');
        key = key.charAt(0).toLowerCase() + key.slice(1)

        input.setAttribute(kebabCase(key), value)
    });

    input.classList.add('form-select__search_input');

    input.addEventListener('input', () => {
        const dropdown = document.querySelector(`.form-select__dropdown[data-parent-id="${parentId}"]`);

        let options = [...parent.options].filter(option => option.text.toLocaleLowerCase('tr-TR').includes(input.value.toLocaleLowerCase('tr-TR')));

        if (!input.value) {
            fillDropdownByParentIdWithSearchResultOptions(dropdown, parent, parentId, options);

            return;
        }

        options = options.filter(option => !option.disabled);

        fillDropdownByParentIdWithSearchResultOptions(dropdown, parent, parentId, options);
    })

    return input;
}

const fillDropdownByParentId = (dropdown, parent, parentId, button = null) => {
    const options = [...parent.options];

    dropdown.innerHTML = '';

    if ('searchable' in parent.dataset) {
        dropdown.appendChild(createSearchInput(parentId));
    }

    appendOptionsToDropdown(dropdown, parentId, options);

    if (!button) return;

    let selectedOptions = options.filter(option => option.selected);
    selectedOptions = selectedOptions.length > 0 ? selectedOptions[0] : null;

    button.innerText = selectedOptions?.label;

    const i = document.createElement('i');

    i.classList.add(selectIcon);

    button.appendChild(i);

    if (selectedOptions && selectedOptions.disabled) {
        button.classList.add('form-select__selection_disabled')
    } else {
        button.classList.remove('form-select__selection_disabled');
    }
}

const fillDropdownByParentIdWithSearchResultOptions = (dropdown, parent, parentId, options) => {
    const currentOptions = dropdown.querySelectorAll('.form-select__dropdown_option');

    currentOptions.forEach(option => {
        option.remove();
    })

    appendOptionsToDropdown(dropdown, parentId, options);
}

const appendOptionsToDropdown = (dropdown, parentId, options) => {
    const optionContainer = document.createElement('div');

    optionContainer.classList.add('form-select__dropdown_option_container');

    options.forEach(option => {
        const _ = document.createElement('div');

        _.setAttribute('class', option.classList.toString());

        _.classList.add('form-select__dropdown_option');

        _.innerText = option.text;
        _.dataset.value = option.value;
        _.dataset.disabled = option.disabled;
        _.dataset.selected = option.selected;

        if (option.disabled) {
            _.classList.add('form-select__selection_disabled');
        }

        if (option.selected) {
            _.classList.add('form-select__selection_selected');
        }

        _.addEventListener('click', () => {
            if (!option.disabled) {
                selectByValue(parentId, option.value)
            }
        });

        optionContainer.appendChild(_);
    });

    dropdown.appendChild(optionContainer);
}

window.onclick = function (event) {
    const openedSelect = document.querySelector('.form-select__container.open');

    if (openedSelect && event.target !== openedSelect && !event.target.closest('.form-select__container.open')) {
        openedSelect.classList.remove('open');
    }
}