document.addEventListener('DOMContentLoaded', () => {
    const cookie = document.querySelector('.cookie');

    if (!cookie) return;

    const cookieButton = cookie.querySelector('.btn');

    if (!cookieButton) return;

    cookieButton.addEventListener('click', () => {
        document.cookie = "cookieAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";


        cookie.style.display = 'none';
    });

    if (document.cookie.split(';').some((item) => item.trim().startsWith('cookieAccepted='))) {
        cookie.style.display = 'none';
    }
});
