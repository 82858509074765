import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {
    const phoneInputs = document.querySelectorAll('.form-phone');

    phoneInputs.forEach(el => {
        // const int = intlTelInput(el,{
        //     initialCountry: "tr",
        // });

        const mask = IMask(el, {
            mask: '(000)  000  00  00',
        });

        el.addEventListener('input', () => {
            el.dataset.unmasked = mask.unmaskedValue;
        });
    })
})