import axios from "axios";
import {Modal} from "./base-ui/modal.js";

document.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('form');

    forms.forEach(form => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const formData = new FormData(form);

            const inputs = [...form.querySelectorAll('[name]')];

            inputs.forEach(el => {
                if ('unmasked' in el.dataset) {
                    formData.set(el.getAttribute('name'), el.dataset.unmasked)
                }

                if (el.nodeName === 'SELECT' || el.nodeName === 'INPUT' && el.type === 'checkbox') {
                    el.addEventListener('change', () => {
                        const parent = el.closest(el.type === 'checkbox' ? '.checkbox-group' : '.form-group');

                        parent.classList.remove('danger');

                        const message = parent.querySelector('.message');
                        message.style.display = 'none';
                        message.innerText = '';
                    });

                    return;
                }

                el.addEventListener('input', () => {
                    const parent = el.closest('.form-group');

                    parent.classList.remove('danger');

                    const message = parent.querySelector('.message');
                    message.style.display = 'none';
                    message.innerText = '';
                });
            });

            axios.post(form.action, formData, {
                headers: {
                    'X-Localization': document.documentElement.getAttribute('lang'),
                }
            })
                .then(function (res) {
                    const modal = new Modal('.modal-success');

                    Modal.closeAllModals();

                    modal.show();

                    const button = modal.element.querySelector('a.modal-close');

                    inputs.forEach(input => {
                        clearInputValue(input);
                    });

                    button.addEventListener('click', () => {
                        modal.hide();

                        button.removeEventListener('click');
                    });
                })
                .catch(function (error) {
                    switch (error.response.status) {
                        case 422:
                            Object.entries(error.response.data.errors).forEach(([key, value]) => {
                                setErrorMessage(form, key, value[0]);
                            });
                            break;
                        case 429:
                            const rateLimit = new Modal('.modal-rate-limit');

                            rateLimit.show();

                            const rateLimitButton = rateLimit.element.querySelector('a.modal-close');

                            rateLimitButton.addEventListener('click', () => {
                                rateLimit.hide();

                                rateLimitButton.removeEventListener('click');
                            });
                            break;
                        default:
                            const modal = new Modal('.modal-danger');

                            modal.show();

                            const button = modal.element.querySelector('a.modal-close');

                            button.addEventListener('click', () => {
                                modal.hide();

                                button.removeEventListener('click');
                            });
                            break;
                    }
                });
        });
    });
});

const setErrorMessage = (form, name, text) => {
    let input = form.querySelector(`[name="${name}"]`);

    if (!input) {
        input = form.querySelector(`[name="${name}[]"]`);
    }

    if (!input) return;

    const parent = input.type === 'checkbox' ? '.checkbox-group' : '.form-group';

    const formGroup = input.closest(parent);

    formGroup.classList.add('danger');

    const message = formGroup.querySelector('.message');

    message.style.display = 'block';

    message.innerText = text;
}

const clearInputValue = (input) => {
    input.value = '';
    input.dataset.unmasked = '';
    input.dispatchEvent(new Event('change'));
}