const uid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

const options = {
    showClass: 'modal-show',
    additionalShowClasses: '',
    onShow: null,
    onHide: null,
    trigger: null,
    closeSelector: '[data-modal-close]',
    keyboard: true,
    hash: null,
};

export class Modal {
    /**
     * Static Properties
     */
    static stack = [];
    static modals = [];

    /**
     * Public Properties
     */
    element = null;
    trigger = null;

    constructor(el, options = {}) {
        this.#elementCreator(el, 'element');

        this.#setOptions(options);

        this.#attachModalIdToModal();

        this.#initTrigger();

        this.#initCloseButtons();

        Modal.modals.push(this);

        this.#initHash();
    }

    /**
     * Static Methods
     */
    static closeAllModals() {
        [ ...Modal.stack ].forEach(modal => modal.hide());
    }

    /**
     * Public Methods
     */
    show() {
        if (!this.#available('element')) {
            return;
        }

        Modal.stack.forEach(modal => {
            modal.element.classList.remove('show');
            modal.element.classList.add('in-background');
        });

        this.element.classList.add(
            this.option('showClass')
        );

        Modal.stack.push(this);

        if (this.option('onShow')) {
            this.option('onShow')(this);
        }

        this.listenToggle();
    }

    hide() {
        if (!this.#available('element')) {
            return;
        }

        Modal.stack.pop();

        const previousModal = Modal.stack.slice(-1);

        if (previousModal.length > 0) {
            previousModal[0].element.classList.add('show');
            previousModal[0].element.classList.remove('in-background');
        }

        this.element.classList.remove(
            this.option('showClass')
        );

        if (this.option('onHide')) {
            this.option('onHide')(this);
        }

        this.listenToggle();
    }

    option(option) {
        return this.options[option];
    }

    /**
     * Private Methods
     */
    #available(properties) {
        if (typeof properties === 'string') {
            properties = [properties];
        }

        return properties.filter(property => !this[property]).length === 0;
    }

    #attachModalIdToModal() {
        if (!this.#available('element')) {
            return;
        }

        this.element.dataset.modalId = uid();
    }

    #initTrigger() {
        this.#elementCreator(this.option('trigger'), 'trigger');

        this.#addTriggerEventListeners();
    }

    #addTriggerEventListeners() {
        if (!this.#available(['element', 'trigger'])) {
            return;
        }

        this.trigger.addEventListener('click', (e) => {
            e.preventDefault();

            this.show();
        });
    }

    #elementCreator(el, property) {
        switch (true) {
            case el instanceof HTMLElement:
                this[property] = el;
                break;
            case typeof el === 'string' && el !== '':
                this[property] = document.querySelector(el);
                break;
            default:
                this[property] = null;
                break;
        }
    }

    #setOptions(_options = {}) {
        this.options = {
            ...options,
            ..._options,
        };
    }

    #initCloseButtons() {
        if (!this.#available('element')) {
            return;
        }

        this.element.querySelectorAll(this.option('closeSelector'))
            .forEach(el => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    this.hide();
                });
            })
    }

    #initHash() {
        if (!this.#available('element')) {
            return;
        }

        if (`#${this.element.dataset.hash}` === window.location.hash) {
            if (Modal.stack.findIndex(modal => modal.element.dataset === this.element.dataset) === -1) {
                this.show();
            }
        }
    }

    listenToggle() {
        if (Modal.stack.length > 0 && !document.body.classList.contains('modal-open')) {
            return document.body.classList.add('modal-open');
        }

        if (Modal.stack.length === 0 && document.body.classList.contains('modal-open')) {
            return document.body.classList.remove('modal-open');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const triggerButtons = document.querySelectorAll('[data-modal-toggle]');
    const closeAllButtons = document.querySelectorAll('[data-modal-close-all]');

    triggerButtons.forEach(el => {
        new Modal(el.dataset.modalToggle, {
            trigger: el,
        });
    });

    closeAllButtons.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            Modal.closeAllModals();
        })
    });
});

document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape' && Modal.stack.length > 0) {
        const modal = Modal.stack.slice(-1)[0];

        if (modal.option('keyboard')) {
            modal.hide();
        }
    }
});