import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        init();
    }, 1);
});

const init = () => {
    // const homepageBenefits = document.querySelector('#homepage-benefits');
    const homepageBlogs = document.querySelector('#homepage-blog');
    const cta = document.querySelector('#cta');
    const footer = document.querySelector('#footer');

    const markers = false;

    // gsap.to(homepageBenefits, {
    //     scrollTrigger: {
    //         trigger: homepageBenefits,
    //         start: "+=10% bottom",
    //         endTrigger: cta,
    //         end: '+=50% bottom',
    //         scrub: 4,
    //         markers,
    //         onUpdate: (self) => self.next()?.refresh(),
    //         onLeave: () => ScrollTrigger.refresh(),
    //         id: 'blogs'
    //     },
    //     marginTop: '-10%',
    //     duration: 40
    // });

    gsap.to(homepageBlogs, {
        scrollTrigger: {
            trigger: homepageBlogs,
            start: "+=10% bottom",
            endTrigger: cta,
            end: '+=50% bottom',
            scrub: 4,
            markers,
            onUpdate: (self) => self.next()?.refresh(),
            onLeave: () => ScrollTrigger.refresh(),
            id: 'blogs'
        },
        marginTop: '-10%',
        duration: 40
    });

    gsap.to(cta, {
        scrollTrigger: {
            trigger: cta,
            start: "top bottom",
            // endTrigger: footer,
            end: '+=100% bottom',
            scrub: 4,
            markers,
            onUpdate: (self) => self.next()?.refresh(),
            onLeave: () => ScrollTrigger.refresh(),
            id: 'cta'
        },
        marginTop: '-10%',
        duration: 40
    });

    gsap.to(footer, {
        scrollTrigger: {
            trigger: footer,
            start: "top bottom",
            end: '+=55% bottom',
            scrub: 4,
            markers,
            id: 'footer',
        },
        marginTop: '-10%',
        duration: 40
    });
}