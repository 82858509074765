/**
 * https://stackoverflow.com/a/24137301
 */
Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
}

const load = (theme) => {
    const space = 40;
    const widthExp = 480;
    const sizeLine = 18;
    const mouse = {x: 0, y:0};
    const point = {x: 0, y:0};
    const pinCount = 216;
    const lineWidth = 2;
    const centerX = -innerWidth;
    const centerY = 300;

    const homepageHero1 = {
        canvas: document.querySelector('#homepage-hero .homepage-hero-pins-1'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        responsive: {
            0: {
                centerX: 60,
                centerY: innerHeight - 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1280: {
                centerX: 240,
                centerY: innerHeight - 360,
                space: 38,
                widthExp: 440,
                sizeLine: 18,
                pinCount: 180
            },
            1536: {
                centerX: 320,
                centerY: innerHeight - 320,
                space: 40,
                widthExp: 480,
                sizeLine: 18,
                pinCount: 204
            }
        },
    }

    const homepageHero2 = {
        canvas: document.querySelector('#homepage-hero .homepage-hero-pins-2'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        responsive: {
            0: {
                centerX: 60,
                centerY: 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: innerWidth - 80,
                centerY: 380,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            },
            1280: {
                centerX: innerWidth - 80,
                centerY: 380,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            },
            1536: {
                centerX: innerWidth - 180,
                centerY: 380,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            }
        },
    }

    const homepageProjects = {
        canvas: document.querySelector('#homepage-projects .homepage-projects-pins'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        colorPicker: randomPinColor2,
        responsive: {
            0: {
                centerX: 60,
                centerY: innerHeight - 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1280: {
                centerX: 240,
                centerY: innerHeight - 360,
                space: 38,
                widthExp: 440,
                sizeLine: 18,
                pinCount: 180
            },
            1536: {
                centerX: 420,
                centerY: innerHeight - 360,
                space: 44,
                widthExp: 580,
                sizeLine: 20,
                pinCount: 345
            }
        },
    }

    const cta1 = {
        canvas: document.querySelector('#cta .cta-pins-1'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        colorPicker: randomPinColor2,
        responsive: {
            0: {
                centerX: 60,
                centerY: innerHeight - 60,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: innerHeight - 100,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: 240,
                centerY: innerHeight - 140,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1280: {
                centerX: 240,
                centerY: innerHeight - 180,
                space: 38,
                widthExp: 440,
                sizeLine: 18,
                pinCount: 180
            },
            1536: {
                centerX: 280,
                centerY: innerHeight - 220,
                space: 40,
                widthExp: 480,
                sizeLine: 18,
                pinCount: 204
            }
        },
    }

    const cta2 = {
        canvas: document.querySelector('#cta .cta-pins-2'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        colorPicker: randomPinColor2,
        responsive: {
            0: {
                centerX: 60,
                centerY: 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: innerWidth - 80,
                centerY: innerHeight - 400,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            },
            1280: {
                centerX: innerWidth - 80,
                centerY: innerHeight - 400,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            },
            1536: {
                centerX: innerWidth - 180,
                centerY: innerHeight - 400,
                space: 36,
                widthExp: 360,
                sizeLine: 14,
                pinCount: 140
            }
        },
    }

    const customersHero = {
        canvas: document.querySelector('#customers-hero .customers-hero-pins'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        responsive: {
            0: {
                centerX: 60,
                centerY: innerHeight - 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: 240,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1280: {
                centerX: 240,
                centerY: innerHeight - 360,
                space: 38,
                widthExp: 440,
                sizeLine: 18,
                pinCount: 180
            },
            1536: {
                centerX: 260,
                centerY: innerHeight - 520,
                space: 40,
                widthExp: 480,
                sizeLine: 18,
                pinCount: 204
            }
        },
    }

    const corporateHero = {
        canvas: document.querySelector('#corporate-hero .corporate-hero-pins'),
        space,
        widthExp,
        sizeLine,
        mouse,
        point,
        pinCount,
        lineWidth,
        centerX,
        centerY,
        responsive: {
            0: {
                centerX: 260,
                centerY: innerHeight - 300,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 168,
            },
            640: {
                centerX: 540,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1024: {
                centerX: 740,
                centerY: innerHeight - 340,
                space: 36,
                widthExp: 420,
                sizeLine: 16,
                pinCount: 180,
            },
            1280: {
                centerX: 640,
                centerY: innerHeight - 260,
                space: 38,
                widthExp: 440,
                sizeLine: 18,
                pinCount: 180
            },
            1536: {
                centerX: 600,
                centerY: innerHeight - 220,
                space: 40,
                widthExp: 480,
                sizeLine: 18,
                pinCount: 204
            }
        },
    }

    setTimeout(() => {
        magneticPins(homepageHero1, theme);
        magneticPins(homepageHero2, theme);
        magneticPins(homepageProjects, theme);
        magneticPins(cta1, theme);
        magneticPins(cta2, theme);
        magneticPins(customersHero, theme);
        magneticPins(corporateHero, theme);
    }, 100);
}

/**
 * https://codepen.io/ziiw/pen/VvBrXJ
 */
let inverse = true;
let circles = false;

const randomPinColor = (theme) => {
    if (theme === 'dark') {
        return [
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#39355C',
            '#00CE7C'
        ].random();
    }

    return [
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#E8ECF4',
        '#00CE7C'
    ].random();
}

const randomPinColor2 = (theme) => {
    if (theme === 'dark') {
        return [
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#273BC0',
            '#00CE7C'
        ].random();
    }

    return [
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#E8ECF4',
        '#273BC0',
        '#273BC0',
        '#273BC0',
        '#00CE7C'
    ].random();
}

const magneticPins = (options, theme) => {
    const canvas = options.canvas;

    if (!canvas) return;

    const replaceOptions = (newOptions = {}) => {
        Object.keys(options).forEach(key => {
            options[key] = newOptions[key] ?? options[key];
        });
    }

    const getOptionsByWindowWidth = (width = null) => {
        if (!width) {
            width = window.innerWidth;
        }

        const breakpoint = Object.keys(options.responsive)
            .map(bp => parseInt(bp))
            .sort((a, b) => b - a)
            .find(bp => bp < width);

        replaceOptions(options.responsive[breakpoint]);

        return options.responsive[breakpoint];
    }

    getOptionsByWindowWidth(innerWidth);

    let w = window.innerWidth;
    let h = window.innerHeight;
    let ctx = canvas.getContext( "2d");

// Locals
    let lines = [];
    let space = options.space;
    let widthExp = options.widthExp;
    let sizeLine = options.sizeLine;
    let mouse = options.mouse;
    let point = options.point;

    window.addEventListener("resize", init, false );
    document.addEventListener("mousemove", handleMove, false );

    function init(){
        w = window.innerWidth;
        h = window.innerHeight;
        canvas.width = w;
        canvas.height = h;
        ctx.lineWidth = options.lineWidth;

        lines = [];

        ctx.fillStyle = 'transparent';

        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();

        let centerX = canvas.width - options.centerX;
        let centerY = canvas.height - options.centerY;

        let posX = centerX - widthExp / 2;
        let posY = centerY - widthExp / 2;

        ctx.strokeWidth = 1;

        for (let i = 0; i < options.pinCount; i++) {
            if(circles){
                ctx.beginPath();
                ctx.arc(posX, posY, 10, 0, Math.PI * 2);
                ctx.strokeStyle = "#333";
                ctx.stroke();
            }

            const color = options.colorPicker ? options.colorPicker(theme) : randomPinColor(theme);

            ctx.beginPath();
            ctx.moveTo(posX, posY);
            ctx.lineTo(posX, posY - sizeLine);
            ctx.strokeStyle = color;
            ctx.stroke();

            lines.push({x1: posX, y1: posY, x2: posX, y2: posY + sizeLine, color });

            if(posX + space >= centerX + widthExp / 2){
                posX = centerX - widthExp / 2;
                posY += space;
            }else{
                posX += space;
            }
        }
    }

    function handleMove(e){
        mouse = {
            x: e.clientX,
            y: e.clientY
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < lines.length; i++) {
            lineCircle(lines[i], mouse);
            lines[i].x2 = point.x;
            lines[i].y2 = point.y;

            if(circles){
                ctx.beginPath();
                ctx.arc(lines[i].x1, lines[i].y1, 10, 0, Math.PI * 2);
                ctx.strokeStyle = "#333";
                ctx.stroke();
            }

            ctx.beginPath();
            ctx.moveTo(lines[i].x1, lines[i].y1);
            ctx.lineTo(lines[i].x2, lines[i].y2);
            ctx.strokeStyle = lines[i].color;
            ctx.stroke();
        }
    }

    function lineCircle(line, mouse){
        let Ax = line.x1;
        let Ay = line.y1;
        let Bx = mouse.x;
        let By = mouse.y;
        let R = sizeLine;

        const distance = Math.sqrt( Math.pow(Bx-Ax, 2)+ Math.pow(By-Ay, 2));

        const vector = {
            x: (Bx-Ax)/distance,
            y: (By-Ay)/distance
        }

        if(inverse){
            point = {
                x: -R*vector.x + Ax,
                y: -R*vector.y + Ay
            }
        }else{
            point = {
                x: R*vector.x + Ax,
                y: R*vector.y + Ay
            }
        }
    }

    init();
}

document.addEventListener('DOMContentLoaded', () => {
    const localStorageSetHandler = function(e) {
        load(e.value);
    };

    document.addEventListener("localStorageChanged", localStorageSetHandler, false);

    load(localStorage.getItem('theme'));
});