const hide = (header) => {
    header.classList.remove('header-fixed');
    header.classList.add('header-hide');
}

const show = (header, removeFixed = false) => {
    if (removeFixed) {
        header.classList.remove('header-fixed');
    } else {
        header.classList.add('header-fixed');
    }
    header.classList.remove('header-hide');
}

document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('header#header');

    if (!header) return;

    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
        let currentScrollTop = window.scrollY;

        if (currentScrollTop <= 0) {
            return show(header, true);
        }

        if (currentScrollTop <= 100) {
            return show(header);
        }

        if (currentScrollTop < lastScrollTop) {
            show(header);
        } else {
            hide(header);
        }

        lastScrollTop = currentScrollTop;
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const element = document.querySelectorAll('#header .dropdown');

    window.addEventListener('scroll', function() {
        element.forEach(el => {
            el.classList.remove('dropdown-open');
        })
    });
});